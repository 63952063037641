import * as React from 'react';
import { Box, Container, Stack, Flex, SimpleGrid } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import ReactPlayer from 'react-player';
import SEO from '../../components/seo';
import Layout from '../../components/layout';
import CustomButton from '../../components/custom-button';
import '../../styles/applications/soil-tie-up.scss';

import PlayIcon from '../../images/play-icon.svg';

const NutrientRunOff = () => {
  const config = {
    config: {
      file: {
        attributes: {
          controlsList: 'nodownload',
        },
      },
    },
    playIcon: <img src={PlayIcon} alt="playicon" />,
    url: 'https://www.youtube.com/watch?v=jD_Kid4EUAA',
    light:
      'https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara-testimonial-min.jpeg',
    playing: true,
    controls: true,
    width: '100%',
  };
  return (
    <Layout>
      <SEO
        title="Reduce Nutrient Loss | Ostara"
        description="Keep nutrients where plants need them while protecting nearby waterways with Root-Activated fertilizer that stays available for plant uptake while preventing leaching into soil."
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
        keywords="Reduce Nutrient Runoff"
      />
      <main>
        <Box w="100%" p={4} className="product-hero-container">
          <Container maxW={1400}>
            <Box
              display="flex"
              flexDirection={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
              paddingTop={[0, 0, 10, 50]}
            >
              <Box
                marginTop={[12, 12, 12, 100]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Stack maxW="50rem" minH="250px" spacing={6}>
                  <h1>Reduce Nutrient Loss</h1>
                  <p>
                    Keep nutrients where plants need them while protecting
                    nearby waterways.
                  </p>
                  <div className="button-wrappers">
                    <CustomButton to="/contact">CONTACT US</CustomButton>
                  </div>
                </Stack>
              </Box>
              <Stack
                spacing={[4, 6, 4, 6, 8]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Box className="benefits-hero-img-container">
                  <StaticImage
                    className="hero-img"
                    placeholder="transparent"
                    src="../../images/nutrient-runoff-hero-img-min.jpeg"
                    alt="Ostara hero img"
                  />
                </Box>
              </Stack>
            </Box>
          </Container>
        </Box>

        <Box p={4} w="100%" className="soil-tie-up-body-container">
          <Flex direction="column">
            <Container
              className="soil-tie-up-body-content-1--container"
              maxW="container.lg"
            >
              <div className="soil-tie-up-body-content-1--heading">
                <h3>
                  Crystal Green<sup>®</sup> Fertilizers Use Crop Driven Release™
                  to Help Protect the Environment
                </h3>
                <p>
                  With patented Crop Driven Release™, Crystal Green stays
                  available for plant uptake due its unique solubility, unlike
                  conventional fertilizers that have the potential to leach
                  nutrients out of the soil and into local waterways.
                </p>
              </div>
              <StaticImage
                className="hero-img"
                placeholder="transparent"
                src="../../images/root-take-v3.jpg"
                alt="Ostara img"
              />
            </Container>
          </Flex>
          <Container maxW={1400}>
            <hr className="custom-divider" />
          </Container>

          <Container className="soil-tie-up-body-2--container" maxW={1400}>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={24}>
              <Flex direction="column" className="soil-tie-up-body-content-2">
                <h3>Nutrients Available to Plants, Not Waterways</h3>
                <p>
                  Minimize the nutrient loss that leads to runoff, and increase
                  fertilizer efficiency, with Crystal Green fertilizer’s Crop
                  Driven Release™ process that releases nutrients only in
                  response to organic acids produced by growing roots.
                </p>
              </Flex>
              <StaticImage
                placeholder="transparent"
                src="../../images/scheme-rain.png"
                alt="Ostara icons"
              />
            </SimpleGrid>
          </Container>
          <Container maxW={1400}>
            <hr className="custom-divider" />
          </Container>

          <Container className="soil-tie-up-body-2--container" maxW={1400}>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={24}>
              <StaticImage
                placeholder="transparent"
                src="../../images/nutrient-runoff-img2-min.png"
                alt="Ostara icons"
              />
              <Flex direction="column" className="soil-tie-up-body-content-2">
                <h3>Organic Acid Soluble</h3>
                <p>
                  Prevent leaching with Crystal Green’s organic acid solubility.
                  Conventional phosphorus fertilizers are water soluble and
                  dissolve quickly into the soil profile—but plants can’t absorb
                  all that phosphorus at once, leaving excess P that can runoff
                  and leach into nearby waterways.
                </p>
              </Flex>
            </SimpleGrid>
          </Container>
          <Container maxW={1400}>
            <hr className="custom-divider" />
          </Container>
          <Container className="soil-tie-up-body-2--container" maxW={1400}>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={24}>
              <Flex direction="column" className="soil-tie-up-body-content-2">
                <h3>Environmentally Responsible Fertilizer</h3>
                <p>
                  Increase profitability and production while creating a
                  positive environmental impact. Crystal Green is a
                  one-of-a-kind environmentally responsible fertilizer, designed
                  to prevent excess nutrients and reduce the risk of runoff with
                  Crop Driven Release™.
                </p>
              </Flex>
              <StaticImage
                placeholder="transparent"
                src="../../images/nutrient-runoff-img3-min.png"
                alt="Ostara icons"
              />
            </SimpleGrid>
          </Container>
          <Container maxW={1400}>
            <hr className="custom-divider" />
          </Container>
          <Container
            className="soil-tie-up-body-3--container"
            maxW="container.md"
          >
            <div className="soil-tie-up-body-3--heading">
              <h3>
                Study Results: Crystal Green Prevents Phosphorus from Leaching
              </h3>
              <p>
                A study of cumulative orthophosphate in leachate as affected by
                phosphorus source shows Crystal Green is considerably less prone
                to leaching when compared to water soluble P sources.
              </p>
            </div>
          </Container>
          <Container
            className="soil-tie-up-body-3--arrow-lines key-top-box"
            maxW="container.lg"
          />
          <Container maxW="container.lg" className="soil-tie-up-body-3--chart1">
            <StaticImage
              placeholder="transparent"
              src="../../images/Cumulative-OR-Graph.png"
              alt="Ostara icons"
            />
          </Container>
          <Container className="key-down-box" maxW="container.lg" />
          <Container
            className="soil-tie-up-body-3--container"
            maxW="container.md"
          >
            <div className="soil-tie-up-body-3--heading">
              <h3>
                Study Results: Crystal Green Remains in Root Zone Weeks After
                Application
              </h3>
              <p>
                Extractable soil test – as affected by sampling depth and P
                source – following eleven weeks of Bermuda grass establishment,
                shows Crystal Green remains in the soil profile for many weeks
                following application, providing long lasting supply of key
                nutrients.
              </p>
            </div>
          </Container>
          <Container
            className="soil-tie-up-body-3--arrow-lines key-top-box"
            maxW="container.lg"
          />
          <Container maxW="container.lg" className="soil-tie-up-body-3--chart1">
            <StaticImage
              placeholder="transparent"
              src="../../images/Soil-Test-Graph.png"
              alt="Ostara icons"
              width={1000}
            />
          </Container>
          <Container className="line-end" maxW="container.lg" />
        </Box>

        <Box w="100%" p={4} className="soil-tie-up-body-container">
          <Container
            className="soil-tie-up-body-content-3--container"
            maxW={1400}
          >
            <SimpleGrid
              marginTop={[16, 40]}
              marginBottom={[16, 0]}
              columns={{ md: 1, lg: 2 }}
              spacing={20}
              className="product-player-container"
            >
              <Box w="100%">
                <h1>Research Proven</h1>
                <p>
                  In this video, Dr. Fred Below, professor at the University of
                  Illinois, discusses his research showing how Crystal Green
                  uses phosphorus more efficiently to help increase yield.
                </p>
              </Box>
              <Box w="100%">
                <ReactPlayer className="module-video" {...config} />
              </Box>
            </SimpleGrid>
          </Container>
        </Box>

        <Box w="100%" p={4} className="soil-bottom-hero-container">
          <div className="overlay" />
          <Container maxW={1400}>
            <Stack
              paddingTop={[16, 150]}
              paddingBottom={[16, 150]}
              maxW="35rem"
              spacing={6}
            >
              <h2>Reduce Nutrient Runoff with Crystal Green Fertilizers</h2>
              <p>
                Crystal Green’s Crop Driven Release™ fertilizers keep phosphorus
                available when crops need them by releasing only in response to
                organic acids produced by roots, instead of being lost to water.
              </p>
              <CustomButton maxW={180} to="/product/crystal-green">
                LEARN MORE
              </CustomButton>
            </Stack>
          </Container>
        </Box>
      </main>
    </Layout>
  );
};

export default NutrientRunOff;
